import React, { Component } from 'react'

class PayDay extends Component {
  componentDidMount () {
  }

  render () {
    return (
      <>
      </>
    )
  }
}
export default PayDay
